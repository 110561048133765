<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="schedule-edit-inner">
      <loading
        v-if="isLoading"
        class="schedule-templates"
      >
        Загрузка шаблонов
      </loading>

      <template v-else>
        <div class="mb-3">
          <div class="mb-2 font-weight-bold">
            Название шаблона
          </div>
          <b-form-input
            v-model="template.name"
            size="sm"
            :state="checkValidation ? !errors['template.name'] : null"
            @input="validateField('template.name', template.name)"
          />
          <div
            v-if="errors['template.name']"
            class="mt-2 text-danger small"
          >
            {{ errors['template.name'][0] }}
          </div>
        </div>

        <div
          v-if="false"
          class="s"
        >
          <div>
            <base-checkbox
              v-model="template.isLiveShedule"
              style="font-size: 14px"
            >
              Дежурный врач (живая очередь)
            </base-checkbox>
          </div>
        </div>

        <div class="mb-3 d-flex justify-content-between">
          <div class="w-50">
            <div class="mb-2 font-weight-bold">
              Дата начала
            </div>
            <base-date-picker
              v-model="template.startDate"
              :error="!!errors['template.startDate']"
              @change="validateField('template.startDate', template.startDate)"
            />
          </div>
          <div class="w-50 ml-3">
            <div class="mb-2 font-weight-bold">
              Дата окончания
            </div>
            <base-date-picker
              v-model="template.endDate"
              :error="isEndTimeEnabled && !!errors['template.endDate']"
              :disabled="!isEndTimeEnabled"
              @change="validateField('template.endDate', template.endDate)"
            />
          </div>
        </div>

        <div class="d-flex mb-3">
          <div class="mb-2 font-weight-bold">
            Включить дату окончания
          </div>
          <base-switcher
            v-model="isEndTimeEnabled"
            class="ml-3"
          />
        </div>

        <div class="mb-4 d-flex">
          <div class="w-50">
            <div class="mb-2 font-weight-bold">
              Время первого приема
            </div>
            <vue-timepicker
              v-model="startTime"
              :minute-interval="timePickerInterval"
              placeholder="Укажите время"
              hour-label="чч"
              minute-label="мм"
              hide-clear-button
              @change="onInputStartTime"
            />
            <div
              v-if="errors['startTime']"
              class="small text-danger mt-2"
            >
              {{ errors['startTime'][0] }}
            </div>
          </div>

          <div class="w-50 ml-3">
            <div class="mb-2 font-weight-bold">
              Время последнего приема
            </div>
            <vue-timepicker
              v-model="endTime"
              :minute-interval="timePickerInterval"
              placeholder="Укажите время"
              hour-label="чч"
              minute-label="мм"
              hide-clear-button
              @change="onInputEndTime"
            />
            <div
              v-if="errors['endTime']"
              class="small text-danger mt-2"
            >
              {{ errors['endTime'][0] }}
            </div>
          </div>
        </div>

        <div
          v-if="!template.isLiveShedule && showingSlots.length"
          class="mb-3 d-flex flex-wrap justify-content-between"
        >
          <div class="d-flex flex-wrap">
            <div
              v-for="slot in showingSlots"
              :key="slot.title"
              :class="{ selected: slot.isSelected }"
              :title="slot.isSelected ? 'Активен' : 'Не активен'"
              class="slot mr-2 mb-2"
              @click="toggleSlotSelection(slot)"
            >
              {{ slot.title }}
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label
            v-for="day of showedDaysOfWeek"
            :key="day.value"
            class="date-label mr-2"
          >
            <input
              v-model="template.daysOfWeek"
              :value="day.value"
              style="display: none;"
              type="checkbox"
              @change="validateField('template.daysOfWeek', template.daysOfWeek)"
            >

            <div class="crm-date-checkbox">
              {{ day.title }}
            </div>
          </label>
          <div
            v-if="errors['template.daysOfWeek']"
            class="small text-danger mt-2"
          >
            {{ errors['template.daysOfWeek'][0] }}
          </div>
        </div>
        <div class="mb-3">
          <div>
            <div class="crm-form-block">
              <p class="crm-label required">
                Часовой пояс
              </p>
              <b-form-select
                v-model="template.timeZone"
                :state="checkValidation ? !errors['template.timeZone'] : null"
                text-field="title"
                value-field="id"
                size="sm"
                :options="UTCChoices"
                class="select-specialization"
                placeholder="Часовой пояс"
              />

              <div
                v-if="errors['template.timeZone']"
                class="small text-danger mt-2"
              >
                {{ errors['template.timeZone'][0] }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClose"
      >
        Закрыть
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="isSaving"
        @click="saveTemplate"
      >
        Сохранить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
import VueTimepicker from 'vue2-timepicker';
import { validationMixin } from 'vuelidate';
import {
  formatTimeZone, getClientTimezoneOffset, dateWithoutTime,
} from '@/helpers/utils';
import { showCustomMessage, showValidationErrorMessage } from '@/helpers/messages';
import {
  set, addMinutes, format, differenceInDays, addDays, formatISO,
} from '@evd3v/date-fns';
import {
  BaseSwitcher, BaseCheckbox, BaseDatePicker,
} from '@/components/base';
import { uniq } from 'lodash';
import { makeValidate, validateData } from '@/validation/zod/main/helper.validation';
import { scheduleTemplateEditModalSchema } from '@/validation/zod/schedule-template-edit-modal/scheduleTemplateEditModal.validation';

export default {
  name: 'ScheduleTemplateEditModal',
  components: {
    BaseSwitcher,
    VueTimepicker,
    BaseCheckbox,
    BaseDatePicker,
  },
  mixins: [validationMixin],
  props: {
    templateProp: {
      type: Object,
      default: () => ({}),
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Редактирование шаблона расписания',
    },
    minuteInterval: {
      type: Number,
      default: 15,
    },
  },
  data() {
    return {
      isOpen: true,
      checkValidation: false,
      isSaving: false,
      daysOfWeek: [
        { title: 'Пн', value: 1 },
        { title: 'Вт', value: 2 },
        { title: 'Ср', value: 3 },
        { title: 'Чт', value: 4 },
        { title: 'Пт', value: 5 },
        { title: 'Сб', value: 6 },
        { title: 'Вс', value: 0 },
      ],
      daysOfWeekValues: {
        Sunday: 0,
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
      },

      startTime: '',
      endTime: '',
      timePickerInterval: 5,
      slots: [],
      allPossibleSlots: [],
      checkedSlots: [],
      selectedSlots: [],

      isLoading: false,
      isEndTimeEnabled: true,

      template: {
        isLiveShedule: false,
        id: null,
        available: true,
        startDate: null,
        endDate: null,
        daysOfWeek: [],
        slots: {
          morning: 0,
          afternoon: 0,
          evening: 0,
        },
        slotTemplateTimes: [],
        staffId: null,
        specializationId: null,
        name: '',
        isExternalSchedule: false,
        timeZone: null,
      },
      errors: {},
    };
  },
  computed: {
    fieldsToValidate() {
      if (this.isEndTimeEnabled) {
        return ['template.name', 'template.startDate', 'template.endDate',
          'template.daysOfWeek', 'template.timeZone', 'startTime', 'endTime'];
      }
      return ['template.name', 'template.startDate', 'template.daysOfWeek', 'template.timeZone', 'startTime', 'endTime'];
    },
    showedDaysOfWeek() {
      if (!this.isEndTimeEnabled && this.template.startDate) {
        return [...this.daysOfWeek];
      }

      if (!this.template.startDate || !this.template.endDate) return [];

      const days = differenceInDays(new Date(this.template.endDate), new Date(this.template.startDate));
      if (days < 0) return [];
      const dates = [...Array(days + 1).keys()].map((i) => addDays(new Date(this.template.startDate), i));
      const daysOfWeek = dates.map((item) => new Date(item).getDay());

      const uniqDaysOfWeek = uniq(daysOfWeek);

      return this.daysOfWeek.filter((item) => uniqDaysOfWeek.includes(item.value));
    },
    doctorSpecializations() {
      return this.doctor.specializations;
    },
    showingSlots() {
      return this.slots;
    },
    doctor() {
      return this.$store.state.Schedule.selectedDoctor;
    },
    doctorId() {
      return this.doctor.id;
    },
    UTCChoices() {
      const choices = [];

      for (let i = -12; i <= 14; i++) {
        choices.push({
          id: i,
          title: formatTimeZone(i),
        });
      }

      return choices;
    },
  },
  async created() {
    if (this.templateProp) {
      const { startDate, endDate } = this.templateProp;
      this.template = {
        ...this.templateProp,
        startDate: startDate ? new Date(startDate) : null,
        endDate: endDate ? new Date(endDate) : null,
        slots: {
          ...this.templateProp.slots,
        },
        daysOfWeek: this.templateProp.daysOfWeek.map((day) => this.daysOfWeekValues[day]),
      };

      this.isEndTimeEnabled = !!this.templateProp.endDate;
      this.parseSlots(); // Загрузка и парсинг слотов из шаблона
    } else {
      this.createSlots(); // Генерация слотов, если шаблон не передан
      this.template.staffId = this.doctor.mainDoctorSpecialization.staffId;
      this.template.specializationId = this.doctor.doctorSpecializations.find((spec) => spec.isMain).specializationId;
      this.template.timeZone = getClientTimezoneOffset();
    }
  },
  methods: {
    validateField(field, value) {
      const error = validateData(field, value, scheduleTemplateEditModalSchema);
      if (error) {
        this.$set(this.errors, field, error);
      } else {
        this.$delete(this.errors, field);
      }
    },

    makeValidate(fields) {
      const errors = makeValidate(fields, this, scheduleTemplateEditModalSchema);
      this.errors = [];
      Object.keys(errors).forEach((field) => {
        this.$set(this.errors, field, errors[field]);
      });
    },
    onClose() {
      this.$emit('input', false);
    },
    isNumber(value) {
      // eslint-disable-next-line no-restricted-globals
      return typeof Number(value) === 'number' && !isNaN(value);
    },
    onInputStartTime({ displayTime: val }) {
      if (((this.slots.length && (val !== this.slots[0].title)) || !this.template.slotTemplateTimes.length)) {
        this.createSlots();
      }
      this.validateField('startTime', this.startTime);
    },
    onInputEndTime({ displayTime: val }) {
      if (((this.slots.length && (val !== this.slots[this.slots.length - 1].title)) || !this.template.slotTemplateTimes.length)) {
        this.createSlots();
      }
      this.validateField('endTime', this.endTime);
    },
    createSlots() {
      const slots = [];
      // на тот случай, если мы редактируем уже существующий шаблон, вытаскивая время из пропсов
      if (this.slots.length === 0 && this.template.slotTemplateTimes.length !== 0) {
        // задаем начальное время
        if (this.template.slotTemplateTimes[0].startTime) {
          const { hours } = this.template.slotTemplateTimes[0].startTime;
          const { minutes } = this.template.slotTemplateTimes[0].startTime;

          const starHourTime = hours >= 10 ? hours : `0${hours}`;
          const startMinutesTime = minutes >= 10 ? minutes : `0${minutes}`;

          this.startTime = `${starHourTime}:${startMinutesTime}`;
        }
        // задаем  конечное время
        if (this.template.slotTemplateTimes[this.template.slotTemplateTimes.length - 1].startTime) {
          const { hours } = this.template.slotTemplateTimes[this.template.slotTemplateTimes.length - 1].startTime;
          const { minutes } = this.template.slotTemplateTimes[this.template.slotTemplateTimes.length - 1].startTime;

          const endHourTime = hours >= 10 ? hours : `0${hours}`;
          const endMinutesTime = minutes >= 10 ? minutes : `0${minutes}`;

          this.endTime = `${endHourTime}:${endMinutesTime}`;
        }
      }
      const [startHour, startMinute] = this.startTime.split(':');
      const [endHour, endMinute] = this.endTime.split(':');

      // проверка что все выбранные значения времени выбраны и являются числами
      if (
        this.isNumber(startHour) && this.isNumber(startMinute)
          && this.isNumber(endHour) && this.isNumber(endMinute)) {
        // если проверка пройдена
        let startDate = set(new Date(), { hours: parseInt(startHour, 10), minutes: parseInt(startMinute, 10), seconds: 0 });
        const endDate = set(new Date(), { hours: parseInt(endHour, 10), minutes: parseInt(endMinute, 10), seconds: 0 });

        while (startDate <= endDate) {
          slots.push({
            title: format(startDate, 'HH:mm'),
            isSelected: true,
            originalDate: startDate,
          });
          // переходим к след интервалу
          startDate = addMinutes(startDate, this.minuteInterval);
          // проверяем не превысит ли след слот endDate
          if (startDate > endDate && startDate !== endDate) {
            break;
          }
        }
        this.slots = [...slots];
        this.allPossibleSlots = [...slots];
      }
    },
    parseSlots() {
      this.createSlots();

      this.checkedSlots = this.template.slotTemplateTimes.map((slot) => {
        const hours = slot.startTime.hours >= 10 ? slot.startTime.hours : `0${slot.startTime.hours}`;
        const minutes = slot.startTime.minutes >= 10 ? slot.startTime.minutes : `0${slot.startTime.minutes}`;
        return {
          title: `${hours}:${minutes}`,
          isSelected: true,
        };
      });

      // сравниваем выбранные слоты с доступными слотами
      this.slots = this.allPossibleSlots.map((slot) => {
        const found = this.checkedSlots.find((checkedSlot) => checkedSlot.title === slot.title);
        return {
          ...slot,
          isSelected: !!found,
        };
      });

      // если есть выбранные, то устанавливаем startTime и endTime
      if (this.checkedSlots.length) {
        this.startTime = this.checkedSlots[0].title;
        this.endTime = this.checkedSlots[this.checkedSlots.length - 1].title;
      } else {
        this.startTime = null;
        this.endTime = null;
      }
    },
    // функция для форматирования времени в строку 'HH:mm'
    formatTime(date) {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
    },
    // Функция для выбора слота (удаление / добавление)
    toggleSlotSelection(slot) {
      slot.isSelected = !slot.isSelected;
      if (slot.isSelected) {
        this.selectedSlots.push(slot);
      } else {
        const index = this.selectedSlots.findIndex((s) => s.title === slot.title);
        if (index !== -1) {
          this.selectedSlots.splice(index, 1);
        }
      }
    },
    async saveTemplate() {
      this.checkValidation = true;

      this.makeValidate(this.fieldsToValidate);
      if (Object.keys(this.errors).length > 0) {
        showValidationErrorMessage();
        return;
      }

      const slotTemplateTimes = this.slots.filter((slot) => slot.isSelected).map((slot) => {
        const times = slot.title.split(':');
        const date = new Date();
        date.setHours(times[0]);
        date.setMinutes(times[1]);

        const startTime = {
          hours: date.getHours(),
          minutes: date.getMinutes(),
        };

        date.setMinutes(date.getMinutes() + this.minuteInterval);

        const endTime = {
          hours: date.getHours(),
          minutes: date.getMinutes(),
        };

        return {
          startTime,
          endTime,
        };
      });

      const template = {
        ...this.template,
        startDate: this.template.startDate ? dateWithoutTime(formatISO(this.template.startDate)) : null,
        endDate: this.isEndTimeEnabled ? dateWithoutTime(formatISO(this.template.endDate)) : null,
        slotTemplateTimes,
      };

      delete template.isExternalSchedule;

      try {
        this.isSaving = true;

        if (this.templateProp) {
          await this.$store.dispatch(this.$types.SCHEDULE_TEMPLATE_UPDATE, template);
        } else {
          await this.$store.dispatch(this.$types.SCHEDULE_TEMPLATE_CREATE, template);
        }
        this.$store.commit(this.$types.TOGGLE_SCHEDULE_TRIGGER); // сделает тогл триггера и обновит расписание
        this.onClose();
      } catch (e) {
        console.warn(e);
        showCustomMessage('error', 'Ошибка', e.response?.data?.message);
      } finally {
        this.isSaving = false;
        if (this.$store.state.SchedulePage.schedule.length) {
          this.$store.dispatch(this.$types.SCHEDULE_PAGE_FETCH, true);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-edit {
  display: flex;
  flex-direction: column;
  height: 500px;
}
.schedule-edit-inner {
  padding: 0 10px;
  box-sizing: border-box;
}
.crm-date-checkbox {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  background-color: $blue-light;
  width: 45px;
  height: 45px;
  padding: 0;
  border: none;
  color: $blue-dark;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  transition: background-color 0.2s ease-in-out;
}
input[type="checkbox"]:checked + .crm-date-checkbox {
  color: $white;
  background-color: $blue-dark;
}
.schedule-buttons {
  display: flex;
  justify-content: space-around;
}

.slot {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F3F3F3;
  border-radius: 5px;
  width: 65px;
  height: 30px;
  padding: 0;
  border: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  color: $gray-shadow;

  &.selected {
    color: #fff;
    background-color: $blue-dark;
  }
}

.crm-form-block, .select-specialization {
  width: 100%;
}
</style>
