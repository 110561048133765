import { z } from 'zod';

// eslint-disable-next-line import/prefer-default-export
export const scheduleTemplateEditModalSchema = z.object({
  template: z.object({
    name: z.string().min(1, 'Поле не может быть пустым'),
    startDate: z.date()
      .or(z.literal(null))
      .refine((date) => date !== null, 'Поле не может быть пустым'),
    endDate: z.date()
      .or(z.literal(null))
      .refine((date) => date !== null, 'Поле не может быть пустым'),
    daysOfWeek: z.array(z.unknown()).nonempty({ message: 'Необходимо выбрать даты' }),
    timeZone: z.number().nullable().refine((value) => value !== null && !Number.isNaN(value), {
      message: 'Поле не может быть пустым',
    }),
  }),
  startTime: z.string().min(1, 'Поле не может быть пустым'),
  endTime: z.string().min(1, 'Поле не может быть пустым'),
});
